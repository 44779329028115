.mainMenu {
  border-bottom: none;
  font-weight: bold;
  text-transform: uppercase;
}

.logo {
  width: 115px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex: 1;
  }
}

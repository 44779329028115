@import './src/common/theme/_ant-variables';

.editable-cell {
  position: relative;
}

.editable-row {
  &:hover .editable-cell-value-wrap {
    border-color: @grey3;
  }

  .editable-cell-value-wrap {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 4px 11px;
    min-height: 28px;
    cursor: pointer;
  }
}

.greyed-row {
  background-color: @grey2;
}

.lightblue-row {
  background-color: @lightblue;
}

.ant-table-tbody>tr.ant-table-row.lightblue-row:hover>td {
  background-color: @lightblue2 !important;

  & .editable-cell-value-wrap {
    border-color: @grey5;
  }
}
.ant-table-tbody>tr.ant-table-row.greyed-row:hover>td {
  background-color: @grey3;
  & .editable-cell-value-wrap {
    border-color: @grey5;
  }
}

@white: #ffffff;@grey1: #f4f4f4;@grey2: #e6e6e6;@grey3: #dbdbdb;@grey4: #cccccc;@grey5: #878787;@grey6: #666666;@grey7: #333333;@black: #000000;@yellow: #ffcc00;@orange: #ff6600;@red: #da251d;@blackberry: #811d77;@blue: #005ca2;@lightblue: #d1f0fd;@lightblue2: #bdeafd;@turquoise: #00cccc;@green: #009240;@light-green: #ccffcc;@primary-color: @orange;@text-color: @grey7;@heading-color: @grey7;@layout-header-background: @white;@layout-body-background: @white;@layout-footer-background: @grey5;@table-bg: @white;@table-header-bg: @grey4;@table-border-color: #f0f0f0;
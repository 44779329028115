.apn-filter {
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  .apn-filter-main-part {flex: 1; overflow-y: auto; z-index: 101;}

  .apn-buttons {
    margin-top: 12px;
  }
}
@white: #ffffff;@grey1: #f4f4f4;@grey2: #e6e6e6;@grey3: #dbdbdb;@grey4: #cccccc;@grey5: #878787;@grey6: #666666;@grey7: #333333;@black: #000000;@yellow: #ffcc00;@orange: #ff6600;@red: #da251d;@blackberry: #811d77;@blue: #005ca2;@lightblue: #d1f0fd;@lightblue2: #bdeafd;@turquoise: #00cccc;@green: #009240;@light-green: #ccffcc;@primary-color: @orange;@text-color: @grey7;@heading-color: @grey7;@layout-header-background: @white;@layout-body-background: @white;@layout-footer-background: @grey5;@table-bg: @white;@table-header-bg: @grey4;@table-border-color: #f0f0f0;